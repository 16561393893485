<script>
import { announcement, userMessageList } from "@/api/my";
export default {
    name:'MessageCenter',
    data() {
        return {
            selected: "",
            active: "notice",
            list: [],
            userMessageList: [],
            loading_data: true,
            loading_data1: true,
            page: 1,
            pageSize: 20,
            totalPage: "",
            header:{
                mainTitle:getWord('site_message2'),
                mainTitle2:getWord(['message', 'announcement']),
            },
            activedTab:'announcement',
            tabList:[
                {
                    id:'1',
                    keyword: 'announcement',
                    active: true,
                },
                {
                    id:'2',
                    keyword: 'message',
                    active: false,
                },
            ],
        };
    },
    components:{
        Header: () => import('components/base/Header'),
        'mt-header': app.Mint.Header,
    },
    directives: {
        'infinite-scroll': app.Mint.InfiniteScroll,
    },
    methods: {
        left_click() {
            let hisNum = parseInt(window.history.length);
            if (hisNum == 1) {
                this.$router.push("/home");
            } else {
                this.$router.go(-1);
            }
        },
        loadMore_data() {
            if (this.page >= this.totalPage) {
                return false;
            } else {
                this.page++;

                active==='notice'
                    ?this.announcement()
                    :this._userMessageList()
            }
        },
        loadMore_data1() {

        },
        notice(id) {
            this.$router.push({ path: "/my/news/newDetail?type=1&id=" + id });
        },
        news(id) {
            this.$router.push({ path: "/my/news/newDetail?type=2&id=" + id });
        },
        //公告
        announcement() {
            announcement(this.page, this.pageSize).then(result => {
                if (result.data.code == "SUCCESS") {
                    for (var i = 0; i <= result.data.result.list.length - 1; i++) {
                        this.list.push(result.data.result.list[i]);
                    }
                    this.totalPage = result.data.result.totalPage;
                } else {
                    app.Mint.Toast(result.data.msg);
                }
            });            
            this.loading_data = false;
        },
        //消息
        _userMessageList() {
            userMessageList(this.page, this.pageSize).then(result => {
                if (result.data.code == "SUCCESS") {
                    this.userMessageList=result.data.result.list;
                    this.totalPage = result.data.result.totalPage;
                } else {
                    app.Mint.Toast(result.data.msg);
                }
            });
        },
        activeTab(item) {
            this.tabList.forEach(item=>{
                item.active=false;
            })
            item.active=true;
            this.activedTab=item.keyword;
        }
    },
    mounted() {
        this.announcement();
        this._userMessageList();
        this.CustomScroll(this.$refs.main.id);
    }
};
</script>
<template>
    <div id="announcement" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
            <!-- <mt-header :title="">
                <i class="iconfont icon-back" slot="left" @click="left_click()"></i>
            </mt-header> -->
            <Header
                :mainTitle="header.mainTitle2"
                :backButton=false
            />
            <div class="myposition"></div>
            <div class="main" id="main" ref="main">            
                <div class="main_middle tab-list">
                    <a class="main_middle_a" v-for="item in tabList" :class="{'active':item.active}" @click="activeTab(item)">{{ getWord(item.keyword) }}</a>
                </div>
                <div v-if="activedTab==='announcement'" v-for="(item,i) in list" :key="i" v-infinite-scroll="loadMore_data" infinite-scroll-disabled="loading_data" infinite-scroll-distance="50">
                    <div class="main_footer" @click="notice(item.id)">
                        <div class="main_footer_text1">{{item.title}}</div>
                        <div class="main_footer_text2">{{new Date(item.created_at*1000).toLocaleDateString().replace(/\//g, "-")}}</div>
                        <div class="main_footer_text3" v-html="item.content"></div>
                    </div>
                </div>
                <div v-if="activedTab=='message'" v-for="(item,i) in userMessageList" :key="i" v-infinite-scroll="loadMore_data1" infinite-scroll-disabled="loading_data1" infinite-scroll-distance="50">
                    <div class="main_footer" @click="news(item.message_id)">
                        <div class="main_footer_text4">{{item.title}}</div>
                        <div class="main_footer_text2">{{new Date(item.created_at*1000).toLocaleDateString().replace(/\//g, "-")}}</div>
                        <div class="main_footer_text3" v-html="item.content"></div>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />
            <div class="main" id="main" ref="main">
                <div class="tab">
                    <a v-for="item in tabList" :class="{'active':item.active}" @click="activeTab(item)">{{ getWord(item.keyword) }}</a>               
                </div>

                <ul v-if="activedTab==='announcement'" v-infinite-scroll="loadMore_data" infinite-scroll-disabled="loading_data" infinite-scroll-distance="50">
                    <li @click="notice(item.id)" v-for="(item,i) in list">
                        <h4>
                            <b>{{item.title}} &nbsp;</b>
                            <span>{{new Date(item.created_at*1000).toLocaleDateString().replace(/\//g, "-") + " " + new Date(item.created_at*1000).toTimeString().substr(0, 8)}}</span>
                        </h4>
                        <content v-html="item.content"></content>
                    </li>
                </ul>

                <ul v-if="activedTab=='message'" v-infinite-scroll="loadMore_data" infinite-scroll-disabled="loading_data" infinite-scroll-distance="50">
                    <li @click="news(item.message_id)" v-for="(item,i) in userMessageList">
                        <h4>
                            <b>{{item.content}} &nbsp;</b>
                            <span>{{item.created_date}}</span>
                        </h4>
                        <content>&nbsp;</content>
                    </li>
                </ul>
            </div>
        </template>
    </div>
</template>
<style type="text/css" lang='scss'>
    .mint-header {
        display: block;
        height: 1rem;
        font-size: .35rem;

        > .is-left {
            position: absolute;
            left: .15rem;
            top: 0;

            .iconfont {
                font-size: .4rem;
                vertical-align: -0.33rem;
            }
        }

        > .mint-header-title {
            line-height: 1rem;
        }
    }
</style>
<style scoped lang='scss'>
#announcement {
    display: flex;
    flex-direction: column;
    overflow: auto;

    &.template-1,
    &.template-2 {

        .tab-list {

            .active {
                color:rgba(255,255,255,1);
                background-color:rgba(71,150,252,1);
            }
        }        
    }

    &.template-3 {

        .main {

            .tab {
                display: flex;
                justify-content: space-around;

                a {
                    font-size: .36rem;
                    border-bottom: 2px solid transparent;
                    width: 50%;
                    text-align: center;
                    padding: .25rem 0;

                    &.active {
                        color:#EC2829;
                        border-bottom: 2px solid #EC2829;
                    }
                }
            }

            ul {

                li {
                    color: #B0B0B0;
                    padding: .25rem;
                    font-size: .24rem;
                    border-bottom: 1px solid #eeeeee;

                    h4 {
                        font-weight: normal;
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 0.2rem;
                        align-items: center;

                        b {
                            font-size: .3rem;
                            font-weight: normal;
                            color: #333333;
                            overflow: hidden;
                            width: 4rem;
                            white-space: nowrap;
                        }

                        span {
                           
                        }
                    }

                    content {
                        display: block;
                        height: .335rem;
                        overflow: hidden;
                    }
                }
            }
        }
    }

    .main {
        height: 100%;
        padding-bottom: .5rem;
        overflow: auto;
    }
}
.myposition {
    background-color: rgba(239, 239, 239, 1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.main_middle {
    width: 7.08rem;
    height: 0.7rem;
    margin: 0 auto;
    margin-top: 0.2rem;
    border: 1px solid rgba(71, 150, 252, 1);
    border-radius: 0.1rem;
}

.main_middle_a {
    float: left;
    height: 0.7rem;
    width: 50%;
    font-size: 0.28rem;
    text-align: center;
    line-height: 0.68rem;
}

.main_footer {
    background-color: white;
    margin: 0 .25rem;
    margin-top: 0.4rem;
    border: 0;
    padding: .25rem;
}

.main_footer_text1 {
    height: 0.3rem;
    font-size: 0.3rem;
    font-weight: 400;
    color: #333333;
    width: 70%;
    float: left;
    overflow: hidden;
    line-height: .33rem;
}
.main_footer_text2 {
    float: right;
    font-size: 0.3rem;
    font-weight: 400;
    color: #333333;
    line-height: .3rem;
}

.main_footer_text3 {
    min-height: 0.3rem;
    font-size: 0.24rem;
    overflow: hidden;
    font-weight: 400;
    color: #999999;
    clear: both;
    padding-top: .25rem;
}

.main_footer_text4 {
    float: left;
    height: 0.29rem;
    width: 4.5rem;
    margin: 0.25rem 0 0 0.2rem;
    font-size: 0.3rem;
    font-weight: 400;
    color: red;
}
</style>
